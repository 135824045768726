import logo from './logo.svg';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
// import Header from './Header';
// import Footer from './Footer';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import Home from '../src/Pages/Home';

function App() {
  return (
    <>
    <Home/>
    </>
  );
}

export default App;
